<!-- eslint-disable vue/max-attributes-per-line -->
<template>
  <div>
    <b-overlay :show="pageLoading">
      <b-card>
        <b-card-title>รหัสอ้างอิง {{ rawData.creditTransactionId }}</b-card-title>
        <b-card-sub-title>
          วันที่ {{ $date(new Date()).format('D-M-YYYY HH:mm:ss') }}
          <small>(วัน-เดือน-ปี)</small>
        </b-card-sub-title>
        <br />
        <b-row v-if="rawData.raw" cols="1" cols-md="2">
          <b-col class="mb-1">
            <b>สถานะ</b>
            <p class="ml-50">
              <b-badge :variant="variantStatusText">
                {{ rawData.raw.status_text_preview }}
              </b-badge>
            </p>
            <b>รหัสอ้างอิงภายนอก</b>
            <p class="ml-50">{{ rawData.raw.outside_id || 'ไม่มี' }}</p>
            <b>ช่องทางการชำระเงิน</b>
            <p class="ml-50">{{ rawData.raw.payment_chanel_preview }}</p>
          </b-col>
          <b-col>
            <app-timeline>
              <app-timeline-item :title="`ยอดเดิม ${rawData.raw.credit_balance || 0} เครดิต`" variant="secondary" />

              <app-timeline-item variant="success">
                <h6>จำนวน {{ rawData.raw.credit_amount || 0 }} เครดิต</h6>
                <div v-if="rawData.raw.payment_chanel_preview === 'promptpay'">
                  <p class="mb-0">เติมเครดิตผ่าน : {{ rawData.raw.payment_chanel_preview }}</p>
                  <p>สร้างโดย : {{ rawData.raw.strip_create_by_preview || '-' }}</p>
                </div>

                <div v-else-if="rawData.raw.payment_chanel_preview === 'ผู้ดูแลระบบ'">
                  <p class="mb-0">{{ rawData.raw.payment_chanel }} จากเว็บหลังบ้าน</p>
                  <p class="mb-0">ชื่อผู้ดูแลระบบ : {{ rawData.raw.strip_create_by_preview || '-' }}</p>
                  <p class="mb-0">หมายเหตุ : {{ rawData.raw.note || '-' }}</p>
                </div>

                <div v-else>
                  <p class="mb-0">เติมเครดิตผ่านรหัส IAP : {{ rawData.raw.json_purchase_data.code }}</p>
                </div>
              </app-timeline-item>

              <app-timeline-item :variant="variantStatusText">
                <h6>ยอดคงเหลือ {{ rawData.raw.credit_total || 0 }} เครดิต</h6>
                <div v-if="rawData.raw.payment_chanel_preview === 'promptpay'">
                  <div v-if="rawData.raw.status_text_preview === 'pending'">
                    <p class="mb-0">ระบบกำลังรอการตรวจสอบอยู่...</p>
                    <div class="my-1">
                      <upload-image-widget @uploadFile="uploadFileSlip" />
                    </div>
                    <div v-if="formSlip.slipImageUrl" class="mb-1 d-flex justify-content-center">
                      <b-img :src="formSlip.slipImageUrl" height="200" width="auto" @click="gZoomImage" />
                    </div>
                    <b-form-textarea v-model="formSlip.note" rows="2" placeholder="หมายเหตุ" class="mb-1" />
                    <b-btn block variant="primary" @click="confirmCreditPending">ยืนยันการเติมเครดิต</b-btn>
                  </div>
                  <div v-else-if="rawData.raw.status_text_preview === 'succeeded'">
                    <div v-if="rawData.raw.transaction_images.length > 0">
                      <p>ยืนยันการเติมเครดิตโดย : {{ rawData.raw.confirm_by_preview || '-' }}</p>
                      <div v-for="(slipTransaction, index) in rawData.raw.transaction_images" :key="index">
                        <div class="mb-1 d-flex justify-content-center">
                          <b-img :src="slipTransaction.outside_url" height="200" width="auto" @click="gZoomImage" />
                        </div>
                        <p>หมายเหตุ : {{ slipTransaction.note }}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </app-timeline-item>
            </app-timeline>
          </b-col>
        </b-row>
      </b-card>
    </b-overlay>
    <!-- <pre>{{ rawData }}</pre> -->
    <!-- <pre>{{ formSlip }}</pre> -->
  </div>
</template>

<script>
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import mediumZoom from 'medium-zoom'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    AppTimeline,
    AppTimelineItem,
    ToastificationContent,
  },
  data() {
    return {
      rawData: {
        clientId: '',
        creditTransactionId: '',
        raw: null,
      },
      formSlip: {
        slipImageFile: null,
        slipImageUrl: '',
        note: '',
      },
      pageLoading: false,
    }
  },
  computed: {
    variantStatusText() {
      let v = 'secondary'

      switch (this.rawData.raw.status_text_preview) {
        case 'failed':
          v = 'danger'
          break
        case 'succeeded':
          v = 'success'
          break
        case 'pending':
          v = 'warning'
          break
        default:
          break
      }

      return v
    },
  },
  created() {
    this.queryMainData()
  },
  methods: {
    async queryMainData() {
      this.pageLoading = true
      const { clientId, creditTransactionId, row } = this.$route.params
      // this.rawData = { clientId, creditTransactionId, raw: row }
      this.rawData.clientId = clientId
      this.rawData.creditTransactionId = creditTransactionId
      const resp = await this.api.get(
        `api/supports/client-transaction-data/find-one-transaction-data/${creditTransactionId}`,
      )
      if (typeof resp === 'object') {
        this.rawData.raw = { ...resp }
      }
      this.pageLoading = false
    },
    uploadFileSlip(fileObj) {
      const { file, blobUrl } = fileObj

      if (file.fileSizeMB > 2) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'รูปต้องมีขนาดไม่เกิน 2 MB',
            icon: 'BellIcon',
            variant: 'danger',
          },
        })

        return
      }
      this.formSlip.slipImageFile = file
      this.formSlip.slipImageUrl = blobUrl
    },
    zoomImage(ev) {
      const zoom = mediumZoom({ background: '#191f32', margin: 48 })
      zoom.attach(ev.target)
      zoom.on('closed', () => zoom.detach(), { once: true })
    },
    async confirmCreditPending() {
      // 'user_id'   => 'required|string',
      //       'image'     => 'required|image|size:2048|mimes:jpeg,png,jpg,gif',
      //       'note'      => '',
      //       'transaction_data_id' => 'required|string',
      // validate size image ไม่เกิน 2 mb
      // api/supports/credit/confirm-credit-purchase

      const { slipImageFile, note } = this.formSlip
      const { clientId, creditTransactionId } = this.rawData

      if (!slipImageFile) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'โปรดแนบสลิปเพื่อเป็นหลักฐาน',
            icon: 'BellIcon',
            variant: 'danger',
          },
        })
        return
      }

      const check = await this.gCheckConfirmV1('ยืนยันการเติมเครดิต')
      if (!check) return
      // console.log('call api')
      // return

      const formData = new FormData()
      formData.append('image', slipImageFile)
      formData.append('user_id', clientId)
      formData.append('transaction_data_id', creditTransactionId)
      formData.append('note', note ?? '')
      this.pageLoading = true
      const resp = await this.api.post('api/supports/credit/confirm-credit-purchase', formData)
      // console.log('confirmCreditPending', resp)
      await this.queryMainData()
      this.pageLoading = false
    },
    checkStringNumber() {},
  },
}
</script>

<style lang="scss" scoped></style>
